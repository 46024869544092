<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4 p-3"
        ><div id="editor" ref="editor">Carregando...</div>
        <div id="iframe-wrapper" :style="iframe.wrapperStyle">
          <iframe
            v-if="loaded"
            :src="iframe.src"
            :style="iframe.style"
            :height="iframe.style.height"
            :width="iframe.style.width"
            type="application/pdf"
            frameborder="0"
          ></iframe>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Index",
  data() {
    return {
      loaded: false,
      iframe: {
        src: "//valorem.bitrix24.site/visita_prospeccao_interno/",
        style: null,
        wrapperStyle: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.VISITAS_PROSPECCAO") },
    ]);
    let editor = this.$refs.editor;
    this.iframe.style = {
      position: "absolute",
      width: window.innerWidth,
      height: window.innerHeight,
      top: -editor.offsetTop + "px",
      left: -editor.offsetLeft + "px",
    };
    this.iframe.wrapperStyle = {
      overflow: "hidden",
      height: editor.clientHeight + "px",
      width: editor.clientWidth + "px",
    };
    this.loaded = true;
  },
  methods: {},
};
</script>

<style scoped>
.div-iframe-valorem {
  position: absolute;
  top: 0;
  left: 0;
}
/* Diversos Dispositivos */
/**Smartphones */
@media screen and (max-width: 480px) {
  .div-frame-bitrix {
    padding-bottom: 210%;
    position: relative;
    display: block;
    width: 100%;
  }
}

/**Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .div-frame-bitrix {
    padding-bottom: 120%;
    position: relative;
    display: block;
    width: 100%;
  }
}

/**Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .div-frame-bitrix {
    padding-bottom: 115%;
    position: relative;
    display: block;
    width: 100%;
  }
}

/**Bigs */
@media (min-width: 1025px) {
  .div-frame-bitrix {
    padding-bottom: 56.25%;
    position: relative;
    display: block;
    width: 100%;
  }
}
</style>
